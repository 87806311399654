import React from "react";
import {Box, Typography} from "@mui/material";
import {CatalogTypeContent} from "./CatalogTypeContent";

export const CatalogTypeBox: React.FC<any> = ({content, type,id}) => {
    const hash = window.location.hash

    React.useEffect(() => {

        if (hash !== '') {
            const element = document.querySelector(hash)!
            element.scrollIntoView(true)
        }
    },[])
    return (
        <Box id={id} sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            width: '100%',
            backgroundImage: 'linear-gradient(90deg, transparent, rgba(0,0,0,0.76), transparent)',
            //backgroundColor: '#ffffff',
            position: 'relative',
           // backdropFilter: 'blur(10px)',
            padding: '20px 0'
        }}>
            <Typography sx={{fontSize: '2rem', fontWeight: 300, textAlign: 'center'}}>{type}</Typography>
            {
                content.length ?
                    <CatalogTypeContent content={content}/>
                    :
                    <Typography>К сожалению, в данной категории еще нет товара :(</Typography>
            }
            <Box sx={{
                backgroundImage: 'linear-gradient(90deg, transparent, #d3a188, transparent)',
                height: '2px',
                position: 'absolute',
                top: 0,
                width: '100%',
                left: '50%',
                transform: 'translateX(-50%)'}}/>
            <Box sx={{
                backgroundImage: 'linear-gradient(90deg, transparent, #d3a188, transparent)',
                height: '2px',
                position: 'absolute',
                bottom: 0,

                width: '100%',
                left: '50%',
                transform: 'translateX(-50%)'}}/>

        </Box>
    )
}
