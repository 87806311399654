import React from "react";
import {Box, Drawer, Typography} from "@mui/material";
import {CatalogEngine} from "./CatalogEngine.class";
import WallpaperIcon from '@mui/icons-material/Wallpaper';
import {useNavigate} from "react-router-dom";

export const CatalogCard: React.FC<any> = ({card}) => {
    const [x,setX] = React.useState(50)
    const [y,setY] = React.useState(50)
    const [cursor, setCursor] = React.useState('pointer')
    const navigate = useNavigate()

    const photo = card.productPhoto?.photo?.node?.mediaDetails?.sizes !== null && card.productPhoto?.photo?.node?.mediaDetails?.sizes.length ?
        card.productPhoto?.photo?.node?.mediaDetails?.sizes[0]?.sourceUrl :
        (
            card.productPhoto.photo !== null ? card.productPhoto.photo.node.sourceUrl : ''
        )

    const price = card.productsSchema.price !== null ? CatalogEngine.parseCost(card.productsSchema.price)+' ₽' : 'Цена не указана'

    const goto = () => {
        window.open(`/product/${card.id}`, '_blank')

    }

    return (
        <>
        <Box sx={{
            width: 300,
            height: 400,
            backgroundImage: 'linear-gradient(transparent, rgba(0,0,0,0.76), transparent)', cursor
        }}
            onClick={goto}
        >
            {
                photo === '' ?
                    <Box sx={{height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <WallpaperIcon sx={{width: 100, height: 100}}/>
                    </Box> :
                    <Box
                        //onMouseDown={onMouseDown}
                        //onMouseUp={onMouseUp}
                        sx={{
                            height: 200,
                            backgroundImage: `url(${photo})`,
                            backgroundSize: 'cover',
                            backgroundPosition: `${x}% ${y}%`,
                            cursor: cursor
                        }}/>
            }

            <Typography sx={{ fontSize: '1.3rem', fontWeight: 300}}>
                {card.title}
            </Typography>
            <Typography sx={{opacity: .8 ,fontWeight: 300}}>
                {price}
            </Typography>
        </Box>
        </>
    )
}
