import {FC} from "react";
import {Box} from "@mui/material";
import {ProductMedia} from "./Product.media";
import {ProductDescription} from "./Product.description";
import {IS_MOBILE} from "../../../config";


export const ProductBody: FC<{product: any}> = ({product}) => {
    console.log(product)
    const media = product.productsSchema.photos.nodes.map((el: any) => {
        if (!el.mediaDetails.sizes) {
            return product.productPhoto.photo.node.sourceUrl
        } else {
            return el.mediaDetails.sizes[0]?.sourceUrl
        }
    })
    const video = product.productsSchema.video

    if (video) {
        media.push({video})
    }

    return (
        <Box sx={{gap: IS_MOBILE ? 5 : 0,width: '100%', height: '100%', display: 'flex', flexDirection: IS_MOBILE ? 'column': 'row', alignItems: 'flex-start', justifyContent: 'flex-start', pt: 1, pb: IS_MOBILE ? 10: 1}}>
            <ProductMedia media={media} video={video}/>
            <ProductDescription product={product.productsSchema}/>
        </Box>
    )
}
