import {Box, CircularProgress, Typography} from "@mui/material";
import React from "react";
import {setter} from "elum-state/react";
import {PAGE} from "../states";
import {icons} from "../components/pages/Contacts/icons";
import {IS_MOBILE} from "../config";

const style = {
    position: 'relative' as 'relative'
}


export const Contacts = () => {

    React.useEffect(() => {
        setter(PAGE, {
            title: 'Контакты',
            link: '/contacts'
        });
    },[])
    return (
        <Box style={style}>
            <Box className='center' sx={{width: '100%', height: `'fit-content'`, display: 'flex', justifyContent: 'center', mt: 5}}>
                <Box  sx={{gap: 5,minHeight: 400, background: 'rgba(0,0,0,0.8)', borderRadius: '20px', flexDirection: 'column', padding: '1rem 2rem', display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between'}}>
                    <Typography sx={{fontSize: '2rem', fontWeight: 300, textAlign: IS_MOBILE ? 'center' : ''}}>
                        ИП Бушуев Олег Леонидович (МАКО)
                    </Typography>
                    <Box sx={{display: 'flex', gap:  !IS_MOBILE ? 5 : 1, flexWrap: 'wrap', alignItems: 'center', textAlign: 'center', flexDirection:!IS_MOBILE?'row':'column'}}>
                        <Typography sx={{ textAlign: 'center'}}>
                            ИНН 330502733827
                        </Typography>
                        <Typography>
                            тел. 89106726456
                        </Typography>
                        <Typography>
                            ОГРНИП 318332800016920
                        </Typography>
                        <Typography>
                            e-mail: Oleg-kc@yandex.ru
                        </Typography>
                    </Box>
                    <Box sx={{display: 'flex', gap: 5, flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center'}}>
                        {
                            icons.map(el => <a key ={`${el.id}_icons_menu`} style={{textAlign:'center'}} href={el.link} target='_blank'>{el.icon}</a>)
                        }
                    </Box>
                    <Box sx={{display: 'flex', justifyContent: 'space-between',flexDirection:!IS_MOBILE?'row':'column', gap: !IS_MOBILE ? 0 : 5, width: '100%'}}>
                        <iframe  style={{ width: !IS_MOBILE ? "463px" : '100%', height: "281px",borderRadius: '10px', opacity: 1}}
                            src="https://yandex.ru/map-widget/v1/?um=constructor%3A31ad7a4bf9841c1430dc0bc83e60327d370151e4a190dd5e02645c9a063e4f4d&amp;source=constructor" frameBorder="0"></iframe>
                        <Box sx={{maxWidth: !IS_MOBILE ? '50%' : '100%', gap: !IS_MOBILE ? 0 : 5, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center'}}>
                            <Typography sx={{textAlign: 'center', fontSize: !IS_MOBILE ? '1.3rem' : '1rem', fontWeight: 300, whiteSpace: 'pre-wrap'}}>
                                Адрес фактический: 601901, Владимирская область,
                                г. Ковров, ул. Волго-Донская, 21А
                            </Typography>
                            <Typography sx={{fontSize: '1.8rem', fontWeight: 300, textAlign: 'center'}}>
                                Работаем по всей России!
                            </Typography>

                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
