import React from 'react';
import {Box, ThemeProvider} from "@mui/material";
import {AppRoutes} from "./routes/Routes";
import {PageWrapper} from "./wrappers/PageWrapper";
import {theme} from "./styles/mui.theme";
import {SnackbarCloseButton} from "./hooks/useSnackbar";
import {SnackbarProvider} from "notistack";




const App = () => {
    return (
        <Box>
            <SnackbarProvider maxSnack={2} autoHideDuration={1000}
                              action={(key) => <SnackbarCloseButton snackbarKey={key} />} >
                <ThemeProvider theme={theme}>
                    <PageWrapper>
                        <AppRoutes/>
                    </PageWrapper>
                </ThemeProvider>
            </SnackbarProvider>
        </Box>
)};

export default App;
