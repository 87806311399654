import React from "react";
import {Box, Button, TextField, Typography} from "@mui/material";
import {icons} from "./icons";
import SearchIcon from "@mui/icons-material/Search";
import {IS_MOBILE} from "../../config";

const style = {
    backgroundImage: 'url(/assets/backgrounds/footer_bg.png)',
    backgroundSize: 'cover',
    display: 'flex',
    flexDirection: !IS_MOBILE ? 'row' : 'column',
    paddingTop: !IS_MOBILE ? '' : '20px',
    paddingBottom: !IS_MOBILE ? '' : '20px',
    alignItems: 'center',
    justifyContent: 'space-between'
}

export const Footer = () => {

    const [email, setEmail] = React.useState('');


    const sendEmail = async (e: React.FormEvent) => {
        e.preventDefault()
        const reg = /.*@.*\..*/
        const isEmail = reg.test(email)

    }
    return (
        <Box className='center' width='100%' minHeight='20vh' position={'relative'} sx={{background: 'rgba(14, 14, 14, 0.8)',...style}}>
            <Box sx={{
                backgroundImage: 'linear-gradient(90deg, transparent, #d3a188, transparent)',
                height: '2px',
                position: 'absolute',
                top: 0,
                width: '100%',
                left: '50%',
                transform: 'translateX(-50%)'}}/>

            <Box display='flex' flexDirection='column' alignItems='center'>
                <Typography sx={{fontSize: '1.8rem'}}>
                    M A K O .
                </Typography>
                <Typography sx={{fontSize: '.8rem', fontWeight: 300, opacity: .5}}>
                    И.П. Бушуев О.Л.
                </Typography>
            </Box>

            <Box display='flex' flexDirection='column' sx={{alignItems: !IS_MOBILE ? 'flex-start' : 'center'}}>
                <Typography sx={{fontSize: '.8rem', fontWeight: 300, opacity: .5, maxWidth: 500, textAlign: !IS_MOBILE ? '' : 'center'}}>
                    Сведения, размещенные на сайте, носят исключительно информационный характер и не являются публичной офертой. Просим вас дополнительно уточнять указанные данные по телефону или эл. почте.
                </Typography>
                <Typography sx={{fontSize: '.8rem', fontWeight: 300, opacity: .5, textDecoration: 'underline', cursor: 'pointer'}}>
                    Политика конфидециальности
                </Typography>
            </Box>

            <Box display='flex' flexDirection='column' sx={{alignItems: !IS_MOBILE ? 'flex-start' : 'center'}} gap={2}>
                <Typography  sx={{fontSize: '1.8rem', fontWeight: 300}}>
                    Контакты
                </Typography>

                <Box
                    sx={{cursor: 'pointer',
                        backgroundImage: 'linear-gradient(#888888, #888888)',
                        backgroundPosition: '0% 100%',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: '0% 1px',
                        transition: 'background-size 0.3s',
                        "&:hover": {
                            backgroundSize: '100% 1px',
                        }
                    }}
                    >
                    <a style={{fontSize: '.8rem', fontWeight: 300, opacity: .5, textDecoration: 'none', }} href='tel:79106726456'>
                        7 910 672 64 56
                    </a>
                </Box>

                <Box
                    sx={{cursor: 'pointer',
                        backgroundImage: 'linear-gradient(#888888, #888888)',
                        backgroundPosition: '0% 100%',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: '0% 1px',
                        transition: 'background-size 0.3s',
                        "&:hover": {
                            backgroundSize: '100% 1px',
                        }
                    }}
                >
                    <a
                        style={{fontSize: '.8rem', fontWeight: 300, opacity: .5, textDecoration: 'none'}}
                        href='mailto:oleg-kc@yandex.ru'
                    >
                        oleg-kc@yandex.ru
                    </a>
                </Box>



                <Box width='100%' display='flex' alignItems='center' justifyContent='space-between' sx={{"& svg": {cursor: 'pointer'}}}>
                    {
                        icons.map(el => <a key ={`${el.id}_icons_menu`} style={{textAlign:'center'}} href={el.link} target='_blank'>{el.icon}</a>)
                    }
                </Box>
            </Box>

            <Box display='flex' flexDirection='column' alignItems='center' gap={2}>
                <Typography sx={{fontSize: '1rem', fontWeight: 300}}> Заказать коммерческое предложение</Typography>
                <form onSubmit={sendEmail} style={{overflow: 'visible', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 5}}>
                    <TextField
                        size='small'
                        sx={{
                            overflow: 'visible',
                            width: '100%',
                            "& *, &": {
                                color: '#fff !important',
                                borderColor: '#fff !important',
                                borderRadius: '10px !important'
                            },
                            "& > .MuiInputBase-input:hover, &:hover": {
                                borderColor: '#fff !important'
                            }
                        }}
                        value={email}
                        onChange={({target: {value}}) => setEmail(value)}
                        label="Email"
                        variant="outlined" />
                    <Button onClick={sendEmail} fullWidth variant='contained' sx={{ borderRadius: '10px', fontWeight: 300, color: '#2e2e2e'}}>
                        Заказть
                    </Button>
                </form>
            </Box>
        </Box>
    )
}
