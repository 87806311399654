import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {TableHeader} from "./TableHead";
import {TableBodyBasic} from "./TableBody";
import React from "react";
import {ITableProps} from "../../interfaces/props.interface";

export const BasicTable: React.FC<ITableProps> = ({header, body}) => {
    return (
        <TableContainer component={Paper} sx={{backgroundColor: 'transparent',  backdropFilter: 'blur(10px)'}}>
            <Table sx={{ minWidth: 650, maxHeight: 400}} aria-label="simple table">
                <TableHeader header={header}/>
                <TableBodyBasic body={body}/>
            </Table>
        </TableContainer>
    )
}
