import {Box, Typography} from "@mui/material";
import React from "react";
import {setter} from "elum-state/react";
import {PAGE} from "../states";

const style = {
    width: '100vw',
    height: '100vh',
    backgroundImage: 'url(/assets/backgrounds/background_main.jpg)',
}

export const NotFound = () => {

    React.useEffect(() => {
        setter(PAGE, {
            title: '404',
            link: '/404'
        });
    },[])
    return (
        <Box  sx={style}>
            <Box className='center' sx={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(40,40,40,0.29)', backdropFilter: 'blur(5px)'}}>
                <Typography>
                    К сожалению такой страницы нет :(
                </Typography>

            </Box>
        </Box>
    )
}
