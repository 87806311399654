import {Box, CircularProgress} from "@mui/material";
import React from "react";
import {setter} from "elum-state/react";
import {PAGE} from "../states";
import {client} from "../api/graphql/client/client";
import {GraphQL} from "../api/graphql/queres/GraphQL.queres";
import {AboutContent} from "../components/pages/About/Content";
import {ServicesContent} from "../components/pages/Services/ServicesContent";
import {IS_MOBILE} from "../config";

const style = {
    position: 'relative' as 'relative'
}


export const Services = () => {
    const [isLoading, setIsLoading] = React.useState(true)
    const [content, setContent] = React.useState<any>({})

    const getContent = async () => {
        try {
            const {data} = await client.query({query: GraphQL.Services})
            setIsLoading(false)
            setContent(data.mainContent.contentGroup)
        } catch (e) {

        }
    }

    React.useEffect(() => {
        getContent()
    },[])

    React.useEffect(() => {
        setter(PAGE, {
            title: 'Услуги',
            link: '/services'
        });
    },[])
    return (
        <Box style={style}>
            <Box className='center' sx={{width: '100%', height: `'fit-content'`, display: 'flex', justifyContent: 'center', mt: 15}}>
                {isLoading ? <CircularProgress/> :
                    <ServicesContent content={content}/>
                }
            </Box>
        </Box>
    )
}
