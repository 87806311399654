import React from "react";
import {DocTableRow} from "./DocTableRow";

export const DocTableBody: React.FC<any> = ({content}) => {
    return (
        <>
        {content.map((element: any) =>
                <DocTableRow key={`${element.id}_doc_row`} row={element}/>
            )}
        </>
    )

}
