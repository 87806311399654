import {Box, Button} from "@mui/material";
import React from "react";
import { IAboutContent } from "../../../interfaces/props.interface";
import {IS_MOBILE} from "../../../config";
import {useNavigate} from "react-router-dom";

export const AboutContent: React.FC<IAboutContent> = ({content}) => {
    const navigate = useNavigate()

    return (
        <Box sx={{height: 'fit-content', background: 'rgba(255, 255, 255, 0.8)', borderRadius: '20px', padding: '1rem 2rem', display: 'flex', alignItems: 'center', flexDirection: 'column', width: '100%'}}>
            <Box sx={{width: '100%', "& *":{color: '#000',whiteSpace: !IS_MOBILE ? '' : 'pre-wrap'}}} dangerouslySetInnerHTML={{__html: content}}/>
            <Button
                onClick={() => navigate('/contacts')}
                variant='contained'
                sx={{
                    backgroundColor: '#2e2e2e'
                }}
            > Контакты </Button>
        </Box>
    )
}
