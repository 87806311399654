import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {client} from "./api/graphql/client/client";
import {ApolloProvider} from "@apollo/client";
import {BrowserRouter} from "react-router-dom";
import './styles/global.scss';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
      <ApolloProvider client={client}>
          <BrowserRouter>
              <App />
          </BrowserRouter>
      </ApolloProvider>
);
