import React from "react";
import {Box, Typography} from "@mui/material";
import DescriptionIcon from '@mui/icons-material/Description';
import {IS_MOBILE} from "../../../config";



export  const PriceContent: React.FC<any> = ({content})  => {
    console.log(content)
    const download = (item: any) => {
        const a = document.createElement('a')
        a.download = 'true'
        a.href = item.fileUrl.node.mediaItemUrl
        a.click()
    }

    return (
        <Box  sx={{minHeight: 400, background: 'rgba(255, 255, 255, 0.8)', borderRadius: '20px', padding: '1rem 2rem', display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between'}}>
            <Box sx={{display: 'flex', flexDirection:!IS_MOBILE?'row':'column', gap: !IS_MOBILE ? 0 : 1, width: '100%', justifyContent: 'space-between', flexWrap: 'wrap'}}>
            {content.map((el: any) =>
                <Box onClick={() => download(el)} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer'}}>
                    <DescriptionIcon sx={{width: 100, height: 100, "& path":{color: '#000 !important'}}}/>
                    <Typography sx={{color: '#000', maxWidth: 200, textAlign: 'center', fontSize: '1.4rem'}}>
                        {el.fileName}
                    </Typography>
                </Box>
            )}
            </Box>
        </Box>
    )

}
