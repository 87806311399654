import {Box} from "@mui/material";
import React from "react";
import {setter} from "elum-state/react";
import {PAGE} from "../states";
import {Cards} from "../components/pages/Main/Cards/Cards";
import {IS_MOBILE} from "../config";

const style = {
    position: 'relative',
    zIndex: 1
}

export const Main = () => {

    React.useEffect(() => {
        setter(PAGE, {
            title: 'Главная',
            link: '/'
        });
    },[])
    return (
        <Box  sx={style}>
            <Box className='center' sx={{width: '100%', minHeight: `'fit-content' `, display: 'flex', justifyContent: 'center',mt: 10, mb: 10, overflow: 'visible'}}>
                <Cards/>
            </Box>
        </Box>
    )
}
