import {TableBody, TableCell, TableRow} from "@mui/material";
import React from "react";
import {ITableBodyProps} from "../../interfaces/props.interface";

export const TableBodyBasic: React.FC<any> = ({body}) => {
    return(
        <TableBody >
        {body}
        </TableBody>
    )
}
