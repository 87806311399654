import {TableCell, TableHead, TableRow} from "@mui/material";
import React from "react";
import {ITableHeadProps} from "../../interfaces/props.interface";
import {IS_MOBILE} from "../../config";

export const TableHeader: React.FC<ITableHeadProps> = ({header}) => {
    return (
        <TableHead >
            <TableRow sx={{height: IS_MOBILE ? '1vh' : '10vh'}} >
                {
                    header.map((el) =>
                        <TableCell key={`${el.id}_header_table`} sx={{color: '#fff', fontSize: '1.1rem', fontWeight: 300, backgroundColor: 'rgba(0,0,0,0.59)', width: el.width}}>{el.title}</TableCell>
                    )
                }
            </TableRow>
        </TableHead>
    )
}
