import React from "react";
import {Box, TableCell, TableRow, Typography} from "@mui/material";
import {IS_MOBILE} from "../../../config";

export const DocTableRow: React.FC<any> = ({row})  => {
    let {model,price, type} = row.passportSchema
    const isBiggerModel = model.length > 30 || IS_MOBILE
    const wrapperModel = isBiggerModel ? (IS_MOBILE ? '' : model.split('').slice(0,25).join('') + '...') : model
    const [activeModel, setActiveModel] = React.useState(wrapperModel)
    const [openedModel, setOpenedModel] = React.useState(false)

    const openModel = () => {
        if (!openedModel) {
            //document.getElementById(row.id)!.style.height = '200px'
            setOpenedModel(true)
            setActiveModel(model)
        } else {
            //document.getElementById(row.id)!.style.height = ''
            setOpenedModel(false)
            setActiveModel(wrapperModel)
        }

    }


    return (
        <TableRow
            id={row.id}
            key={`${row.id}_row`}
            sx={{transition: '.3s'}}

        >
            <TableCell sx={{border: '1px solid #fff', color: '#fff'}} align="left">
                {row.title}
            </TableCell>
            <TableCell sx={{color: '#fff'}} align="left">
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <pre dangerouslySetInnerHTML={{__html: activeModel}}/>
                    { isBiggerModel ?
                        <Box onClick={openModel} sx={{width: '1rem', height: '1rem', cursor: 'pointer', overflow: 'visible', position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Box sx={{width: '1rem', height: '2px', backgroundColor: '#fff'}}></Box>
                            <Box sx={{width: '1rem', height: '2px', backgroundColor: '#fff', transform: `rotate(${openedModel ? '0' : '90' }deg)`, position: 'absolute', transition: 'all .3s'}}></Box>
                        </Box>
                        : <></>}
                </Box>
            </TableCell>
            <TableCell sx={{border: '1px solid #fff', color: '#fff'}} align="left">{type}</TableCell>
            <TableCell sx={{borderBottom: '1px solid #fff', color: '#fff'}} align="left">{price}</TableCell>
        </TableRow>
    )
}
