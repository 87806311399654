import React from 'react'

import {Navigate, Routes, Route } from 'react-router-dom'
import {Main} from "../pages/Main";
import {About} from "../pages/About";
import {NotFound} from "../pages/404";
import {Services} from "../pages/Services";
import {Passports} from "../pages/Passports";
import {Works} from "../pages/Works";
import {Delivery} from "../pages/Delivery";
import {Prices} from "../pages/Price";
import {Contacts} from "../pages/Contacts";
import {Catalog} from "../pages/Catalog";
import {Product} from "../pages/Product.id";


export const AppRoutes = () => {
    return (
        <Routes>
            <Route path='/' element={<Main/>}/>
            <Route path='/about' element={<About/>}/>
            <Route path='/services' element={<Services/>}/>
            <Route path='/doc' element={<Passports/>}/>
            <Route path='/works' element={<Works/>}/>
            <Route path='/delivery' element={<Delivery/>}/>
            <Route path='/price' element={<Prices/>}/>
            <Route path='/contacts' element={<Contacts/>}/>
            <Route path='/catalog' element={<Catalog/>}/>
            <Route path='/product/:id' element={<Product/>}/>
            <Route path="*" element={<NotFound />}></Route>
        </Routes>
)
}
