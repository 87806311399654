import React from "react";
import {Box, Button} from "@mui/material";
import {setter} from "elum-state/react";
import {SIZED_IMAGE} from "../../../states";
import {IS_MOBILE} from "../../../config";

const getSize = (value: number) => {
    if (value < 70000) {
        return 'small'
    }
    if (value < 480000) {
        return 'medium'
    } else {
        return 'big'
    }
}

export  const WorksContent: React.FC<any> = ({content})  => {
    let {photos} = content
    const openSizedImage = (url: string) => {
        setter(SIZED_IMAGE, url)
    }
    photos = photos.map((el: any) => {
        const images = el.photo.node.mediaDetails.sizes.
        map((photo: any) => ({size: getSize(+photo.width*+photo.height),pixels: +photo.width*+photo.height, url: photo.sourceUrl})).
        filter((photo: any) => photo.size !== 'small').
        sort((a:any,b: any) => a.pixels > b.pixels ? -1 : 1).
        filter((photo: any, i: number, array: any) => i === 0 || i === array.length - 1)
        return {
            images
        }
    })


    return (
        <Box  sx={{padding: '1rem 2rem', maxHeight: 700, background: 'rgba(255, 255, 255, 0.8)', borderRadius: '20px', display: 'flex', alignItems: 'center', flexDirection: 'column', width: '100%', gap: 2}}>
            <Box sx={{display: 'flex', overflowY: 'auto', justifyContent: 'space-between', flexWrap: 'wrap', width: '100%'}}>
                {
                    photos.map((image: any) =>
                        <Box key={image.images[0].url} onClick={() => openSizedImage(image.images[0].url)} sx={{cursor: 'pointer'}}>
                            <img style={{maxWidth: 300, maxHeight: 250 - 30, borderRadius: 5}} src={image.images.length > 1 ? image.images[1].url : image.images[0].url}/>
                        </Box>
                    )
                }

            </Box>

        </Box>
    )

}
