import {Box, CircularProgress, Typography} from "@mui/material";
import {useParams} from "react-router-dom";
import React from "react";
import {client} from "../api/graphql/client/client";
import {GraphQL} from "../api/graphql/queres/GraphQL.queres";
import {setter} from "elum-state/react";
import {PAGE} from "../states";
import {ProductBox} from "../components/pages/Product/Product";

export const Product = () => {
    const {id} = useParams()
    const [product, setProduct] = React.useState()
    const [loading, setLoading] = React.useState(true)
    const getProduct = async () => {
        try {
            const {data} = await client.query({query: GraphQL.getProductById(String(id))})
            setProduct(data.product)
            setLoading(false)
        } catch (e) {

        }
    }

    React.useEffect(() => {
        setter(PAGE, {
            title: 'Каталог',
            link: '/product'
        });
        getProduct()
    },[])

    return (
        <Box className='center' sx={{position: 'relative', zIndex: 1, width: '100%', height:  'fit-content',  display: 'flex', justifyContent: 'center'}}>
            {
                !loading ? <ProductBox product={product}/> : <CircularProgress/>
            }

        </Box>
    )
}
