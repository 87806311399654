import {Box} from "@mui/material";
import React from "react";
import {useGlobalValue} from "elum-state/react";
import {PAGE} from "../../states";
import {HeaderPanel} from "./Panel";
import {IS_MOBILE} from "../../config";

export const Header = () => {
    const state = useGlobalValue(PAGE);
    return (
    <Box position={'relative'} zIndex={2} width={'100%'} paddingBottom={'20px'}>
        <HeaderPanel/>
    </Box>
    )
}
