export const navigation = [
    {
        id: 0,
        title: 'О компании',
        link: 'about'
    },
    {
        id: 1,
        title: 'Каталог',
        link: ''
    },
    {
        id: 2,
        title: 'Услуги',
        link: 'services'
    },
    {
        id: 3,
        title: 'Тех. Документация',
        link: 'doc'
    },
    {
        id: 4,
        title: 'Наши работы',
        link: 'works'
    },
    {
        id: 5,
        title: 'Заказ',
        link: 'delivery'
    },
    {
        id: 6,
        title: 'Прайс-лист',
        link: 'price'
    },
    {
        id: 7,
        title: 'Контакты',
        link: 'contacts'
    },
]
