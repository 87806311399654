export const catalogTypes = [
    {
        id: 0,
        type: 'machine',
        title: 'Станки'
    },
    {
        id: 1,
        type: 'kpo',
        title: 'Кузнечно - прессовое оборудование'
    },
    {
        id: 2,
        type: 'tool',
        title: 'Инструмент'
    },
    {
        id: 3,
        type: 'measure_tool',
        title: 'Мерительный инструмент'
    },
    {
        id: 4,
        type: 'parts',
        title: 'Запчасти'
    },
]
