import {Box, CircularProgress} from "@mui/material";
import React from "react";
import {setter} from "elum-state/react";
import {PAGE} from "../states";
import {client} from "../api/graphql/client/client";
import {GraphQL} from "../api/graphql/queres/GraphQL.queres";
import {AboutContent} from "../components/pages/About/Content";
import {IS_MOBILE} from "../config";

const style = {
    position: 'relative' as 'relative'
}


export const About = () => {
    const [isLoading, setIsLoading] = React.useState(true)
    const [content, setContent] = React.useState('')

    const getContent = async () => {
        try {
            const {data} = await client.query({query: GraphQL.AboutCompany})
            setIsLoading(false)
            setContent(data.mainContent.contentGroup.text)
        } catch (e) {

        }
    }

    React.useEffect(() => {
        getContent()
    },[])

    React.useEffect(() => {
        setter(PAGE, {
            title: 'О компании',
            link: '/about'
        });
    },[])
    return (
        <Box style={style}>
            <Box className='center' sx={{width: '100%', height: `${IS_MOBILE ? 'fit-content' : '100vh'}`, display: 'flex', justifyContent: 'center', marginBottom: 10}}>
                {isLoading ? <CircularProgress/> :
                    <AboutContent content={content}/>
                }
            </Box>
        </Box>
    )
}
