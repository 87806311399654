import {atom} from "elum-state/react";


export const PAGE = atom({
    key: "page",
    default: {
        link: '',
        title: ''
    },
});
export const SIZED_IMAGE = atom({
    key: "sized_image",
    default: '',
});
