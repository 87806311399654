import {createTheme} from '@mui/material';
import {ruRU} from "@mui/material/locale";

export  const theme = createTheme({

    palette: {
        primary: {
            main: '#ffffff',
            contrastText: 'white'
        },
        secondary: {
            main: '#fff',
            contrastText: 'white'
        },
        success: {
            main: '#ffffff',
            contrastText: 'white'
        },
    },
}, ruRU)
