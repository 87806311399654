import React from "react";
import {Box} from "@mui/material";

const style = (visible: boolean) => ({
    position: 'relative',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    width: '100%',
    height: visible ? 200 : 0,
    transition: 'height .5s',
    display: 'flex',
    overflowY: 'hidden',
    overflowX: 'auto',
    alignItems: 'center',
})

const gallery = {
    position: 'absolute',
    left: '2rem',
    paddingRight: '2rem',
    display: 'flex',
    alignItems: 'center',
    overflow: 'auto',
    gap: 10,
}

export const PhotosBlock: React.FC<{onClick: (a: any) => any,visible: boolean,images: Array<any>}> = ({onClick,images, visible}) => {
    //console.log(images[images.length - 1].image.images[1].url)
    return (
        <Box sx={style(visible)}>
            <Box sx={gallery}>
                {
                    images.map((image) =>
                        <Box key={image.images[0].url} onClick={() => onClick(image.images[0].url)} sx={{cursor: 'pointer'}}>
                            <img style={{maxWidth: 200, maxHeight: 200 - 30, borderRadius: 5}} src={image.images.length > 1 ? image.images[1].url : image.images[0].url}/>
                        </Box>
                    )
                }
            </Box>

        </Box>
    )

}
