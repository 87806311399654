import React from "react";
import {Box, Button} from "@mui/material";
import {PhotosBlock} from "./PhotosBlock";
import {setter} from "elum-state/react";
import {SIZED_IMAGE} from "../../../states";
import {IS_MOBILE} from "../../../config";

const getSize = (value: number) => {
    if (value < 70000) {
        return 'small'
    }
    if (value < 480000) {
        return 'medium'
    } else {
        return 'big'
    }
}

export  const ServicesContent: React.FC<any> = ({content})  => {
    let {photos, text} = content
    const [photosVisible, setPhotosVisible] = React.useState(false)
    const openSizedImage = (url: string) => {
        setter(SIZED_IMAGE, url)

    }
    photos = photos.map((el: any) => {
        const images = el.photo.node.mediaDetails.sizes.
        map((photo: any) => ({size: getSize(+photo.width*+photo.height),pixels: +photo.width*+photo.height, url: photo.sourceUrl})).
        filter((photo: any) => photo.size !== 'small').
        sort((a:any,b: any) => a.pixels > b.pixels ? -1 : 1).
        filter((photo: any, i: number, array: any) => i === 0 || i === array.length - 1)
        return {
            images
        }
    })

    return (
        <Box  sx={{minHeight: 400, background: 'rgba(255, 255, 255, 0.8)', borderRadius: '20px', padding: '1rem 0', display: 'flex', alignItems: 'center', flexDirection: 'column', width: '100%', gap: 2}}>
            <Box sx={{padding: '0 2rem', width: '100%', "& *":{color: '#000', fontSize: '1.2rem', textAlign: !IS_MOBILE ? '' : 'center'}}} dangerouslySetInnerHTML={{__html: text}}/>
            <PhotosBlock images={photos} visible={photosVisible} onClick={openSizedImage}/>
            {
                photos.length ?
                    <Button
                        onClick={() => setPhotosVisible(!photosVisible)}
                        variant='contained'
                        sx={{
                            backgroundColor: '#2e2e2e'
                        }}
                    >
                        Примеры работ
                    </Button> : <></>
            }

        </Box>
    )

}
