import {Box} from "@mui/material";
//@ts-ignore
import {ReactComponent as VK} from "../../../assets/contact-icons/vk.svg";
//@ts-ignore
import {ReactComponent as OK} from "../../../assets/contact-icons/ok.svg";
//@ts-ignore
import {ReactComponent as YT} from "../../../assets/contact-icons/yt.svg";
//@ts-ignore
import {ReactComponent as RT} from "../../../assets/contact-icons/rt.svg";
//@ts-ignore
import {ReactComponent as AV} from "../../../assets/contact-icons/av.svg";
//@ts-ignore
import {ReactComponent as TG} from "../../../assets/contact-icons/tg.svg";
//@ts-ignore
import {ReactComponent as VB} from "../../../assets/contact-icons/vb.svg";
//@ts-ignore
import {ReactComponent as WA} from "../../../assets/contact-icons/wa.svg";



export const icons = [
    {
        id: 0,
        icon: <VK/>,
        link: ''
    },
    {
        id: 1,
        icon: <OK/>,
        link: 'https://ok.ru/stanki.osnastka89106726456'
    },
    {
        id: 2,
        icon: <YT/>,
        link: 'https://www.youtube.com/channel/UCkpM358pZ0PcPz59YkixMtA'
    },
    {
        id: 3,
        icon: <RT/>,
        link: 'https://rutube.ru/channel/26629971/'
    },
    {
        id: 4,
        icon: <WA/>,
        link: 'https://wa.me/79106726456?text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5!%20%D0%AF%20%D1%81%20%D1%81%D0%B0%D0%B9%D1%82%D0%B0!'
    },
    {
        id: 5,
        icon: <TG/>,
        link: 'https://t.me/OlegBusch'
    },
    {
        id: 6,
        icon: <VB/>,
        link: 'https://viber.click/79106726456'
    },
    {
        id: 7,
        icon: <AV/>,
        link: 'https://www.avito.ru/profile'
    },
]
