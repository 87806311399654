import React from "react";
import {BasicTable} from "../../Table/Table";
import {DocTableBody} from "./DocTableBody";
import {Box, TextField, Typography} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {IS_MOBILE} from "../../../config";

const header = [
    {
        id: 0,
        title: 'Наименование',
        width: '25%',
    },
    {
        id: 1,
        title: 'Модель',
        width: '35%',
    },
    {
        id: 2,
        title: 'Вид документа',
        width: '15%',

    },
    {
        id: 3,
        title: !IS_MOBILE ? 'Цена ( руб. )' : '( ₽ )',
        width: '15%',

    },
]

export const DocTable: React.FC<any> = ({content}) => {
    const [tableData, setTableData] = React.useState(content)
    const [search, setSearch] = React.useState('')

    const formSubmit = (e: any) => {
        e.preventDefault()
        const newTableData = content.filter((el: any) => {
            if (
                el.title.toLowerCase().includes(search.toLowerCase())
                ||
                el.passportSchema.model.toLowerCase().includes(search.toLowerCase())
                ||
                el.passportSchema.type.toLowerCase().includes(search.toLowerCase())
            ) {
                return true
            }
        })
        setTableData(newTableData)
    }

    React.useEffect(() => {
        if (search === '') {
            setTableData(content)
        }
    },[search])

    return (
        <Box sx={{width: '100%', position: 'relative'}}>
            <Box display='flex' width='100%' justifyContent='flex-end'>
                <form onSubmit={formSubmit}>
                    <TextField
                        sx={{
                            overflow: 'visible',
                            //backgroundColor: 'rgba(0,0,0,0.27)',
                            "& *, &": {
                                color: '#fff !important',
                                fontWeight: 300,
                            },
                            "& > .MuiInputBase-input:hover, &:hover": {
                                borderColor: '#fff !important'
                            },
                            "& > .MuiInputBase-root": {
                                backgroundColor: 'rgba(0,0,0,0.33)'
                            }
                        }}
                        InputProps={{
                            endAdornment: (
                                <SearchIcon sx={{cursor: 'pointer', overflow: 'visible' }} onClick={formSubmit}/>
                            ),
                        }}
                        value={search}
                        onChange={({target: {value}}) => setSearch(value)}
                        label='Поиск'
                        variant='filled'
                        size='small'
                    />
                </form>
            </Box>
            <BasicTable header={header} body={<DocTableBody content={tableData}/>}/>
        </Box>
    )

}
