import {gql} from "@apollo/client";

export class GraphQL {

    static get AboutCompany() {
        return gql`
        query GetMainText {
          mainContent(id: "cG9zdDozOQ==") {
            contentGroup {
              text
            }
          }
        }
`
    }

    static get Services() {
        return gql`
        query Services {
          mainContent(id: "cG9zdDo0MA==") {
            contentGroup {
              text
              photos {
                photo {
                  node {
                    mediaDetails {
                      sizes(exclude: LARGE) {
                        sourceUrl
                        height
                        width
                      }
                    }
                  }
                }
              }
            }
          }
        }
        `
    }

    static get Passports () {
        return gql`
        query Services {
          passports(last: 100) {
            nodes {
              title
              passportSchema {
                model
                price
                type
              }
              id
            }
          }
        }
        `
    }


    static get Works () {
        return gql`
        query Works {
          mainContent(id: "cG9zdDoxMTE=") {
            contentGroup {
             photos {
                 photo {
                   node {
                     mediaDetails {
                       sizes(exclude: LARGE) {
                         sourceUrl
                           height
                           width
                       }
                     }
                   }
                 }
             }
            }
          }
        }
`
    }

    static get Price () {
        return gql`
        query Files {
          mainContent(id: "cG9zdDoxNzE=") {
            title
            contentGroup {
              files {
                fileName
                fileUrl {
                  node {
                    mediaItemUrl
                  }
                }
              }
            }
          }
        }
        `
    }


    static get Products () {
        return gql`
            query Products {
              products (first: 1000) {
                nodes {
                  title
                  id
                  productsSchema {
                    price
                    type
                  }
                  modified
                  productPhoto {
                    photo {
                      node {
                        mediaDetails {
                          sizes(include: MEDIUM_LARGE) {
                            sourceUrl
                          }
                        }
                        sourceUrl
                      }
                    }
                  }
                }
              }
            }
        `
    }

    static getProductsByTaxonomieId(id: string) {
        return gql`
        query Products {
          productTaxonomie(id: "${id}") {
            products(first: 10) {
              nodes {
                title
                id
                productsSchema {
                  price
                }
                modified
                productPhoto {
                  photo {
                    node {
                      mediaDetails {
                        sizes(include: MEDIUM_LARGE) {
                          sourceUrl
                        }
                      }
                      sourceUrl
                    }
                  }
                }
              }
            }
          }
        }
        `
    }

    static getAllTaxonomies() {
        return gql`
        query AllTaxonomies {
          productsTaxonomies(where: {parent: 0}) {
            edges {
              node {
                id
                name
                children {
                  nodes {
                    count
                  }
                }
              }
            }
          }
        }
        `
    }
    static getByIdTaxonomies(id: string) {
        return gql`
        query GetByIdTaxonomie {
          productTaxonomie(id: "${id}") {
           children {
            edges {
                  node {
                    id
                    name
                    children {
                      nodes {
                        count
                      }
                    }
                  }
                }
              }
          }
        }
        `
    }


    static getProductById(id: string) {
        return gql`
        query ProductBuId {
           product(id: "${id}") {
                id
                title
                productsSchema {
                  model
                  description
                  price
                  quantity
                  video
                  year
                  photos {
                    nodes {
                      mediaDetails {
                        sizes(include: LARGE) {
                          sourceUrl
                        }
                      }
                    }
                  }
                }
                productPhoto {
                  photo {
                    node {
                      mediaDetails {
                        sizes(include: LARGE) {
                          sourceUrl
                        }
                      }
                      sourceUrl
                    }
                  }
                }
              }
        }
        `
    }
}
