import {Box, CircularProgress, Typography} from "@mui/material";
import {Card} from "./card/card";
//import {cards} from "./cards.mock";
import {IS_MOBILE} from "../../../../config";
import React from "react";
import {client} from "../../../../api/graphql/client/client";
import {GraphQL} from "../../../../api/graphql/queres/GraphQL.queres";
import {ITaxonomie} from "../../../../interfaces/objects.interface";
import {CatalogTypeBox} from "../../Catalog/CatalogTypeBox";
import {mergeDeep} from "@apollo/client/utilities";

const styleWeb = {
    display: 'grid',
    gridTemplateColumns: 'repeat( 3, 1fr)',
    width: '100%',
    gap: '1rem',
    overflow: 'visible',
}
const styleMobile = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '1rem',
    overflow: 'visible'
}



export const  Cards = () => {
    const [cards, setCards] = React.useState<ITaxonomie[]>([])
    const [menuStack, setMenuStack] = React.useState<any[]>([])
    const [loading, setLoading] = React.useState(true)
    const [isData, setIsData] = React.useState(false)
    const [content, setContent] = React.useState()

    const getTaxonomies = async () => {
        try {
            const {data} = await client.query({query: GraphQL.getAllTaxonomies()})
            const parsedData: ITaxonomie[] = data.productsTaxonomies.edges.map( (el: any) => {
                const {node: {id, name}} = el
                return {
                    id,name,
                    childrens: el.node.children.nodes.length > 0
                }
            })
            setMenuStack([...menuStack, {title: 'Главная', value: parsedData, id: '1', type: 'menu'}])
            setCards(parsedData)
            setLoading(false)
        } catch (e) {

        }
    }

    const getTaxonomyByParentId = async (id: string, name: string) => {
        setLoading(true)


        try {
            const {data} = await client.query({query: GraphQL.getByIdTaxonomies(id)})
            const parsedData: ITaxonomie[] = data.productTaxonomie.children.edges.map( (el: any) => {
                const {node: {id, name}} = el
                return {
                    id,name,
                    childrens: el.node.children.nodes.length > 0
                }
            })
            setMenuStack([...menuStack, {title: name, value: parsedData, id, type: 'menu'}])
            setCards(parsedData)
            setLoading(false)
        } catch (e) {

        }
    }

    const getContent = async (id: string, name: string) => {
        setLoading(true)
        try {
            const {data} = await client.query({query: GraphQL.getProductsByTaxonomieId(id)})
            setIsData(true)
            setLoading(false)
            setMenuStack([...menuStack, {title: name, value: data.productTaxonomie.products.nodes, id, type: 'catalog'}])
            setContent(data.productTaxonomie.products.nodes)
        } catch (e) {

        }
    }

    const clickOnMenu = (id: string) => {
        const index = menuStack.findIndex((el) => el.id === id)
        const element = menuStack.find((el) => el.id === id)
        const newStack = menuStack.slice(0, index + 1)
        setMenuStack(newStack)
        if(element.type === 'menu') {
            setIsData(false)
            setCards(element.value)
        } else {
            setIsData(true)
            setContent(element.value)
        }

    }


    React.useEffect(() => {
        getTaxonomies()
    },[])


    return (
        <>{ !loading ?
        <Box sx={{width: '100%', overflow: 'visible', height: 'fit-content'}}>

            <Box className={'center'} position={IS_MOBILE ? 'relative' : 'absolute'} left={0} top={IS_MOBILE ? 0 : 0} sx={{ display: 'flex', gap: '3px'}}>
                {
                    menuStack.length > 1 ?
                        menuStack.map((el, i) =>
                            <Box  key={`${el.id}_menu_${i}`} sx={{ display: 'flex', gap: '3px'}}>
                                <Typography onClick={() => clickOnMenu(el.id)} sx={{fontWeight: 300, cursor: 'pointer'}}>
                                    {el.title}
                                </Typography>
                                <Typography sx={{fontWeight: 300}}>
                                    {i < menuStack.length - 1 ? ' >' : ' '}
                                </Typography>
                            </Box>
                        )
                        :
                        ''
                }
            </Box>
            {!isData ?
                <Box sx={IS_MOBILE ? styleMobile : styleWeb}>
                    {
                        cards.map((el) =>
                            <Card
                                key={`${el.id}_card_main`}
                                getTaxonomyByParentId={getTaxonomyByParentId}
                                childrens={el.childrens}
                                name={el.name}
                                id={el.id}
                                getContent={getContent}
                            />)
                    }
                </Box>
                :
                <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', width: '100%', gap: 10}}>
                    <CatalogTypeBox
                        type={menuStack[menuStack.length - 1].title}
                        id={'test'}
                        content={content}
                    />
                </Box>
            }
        </Box>
        :
        <CircularProgress/>
        }
        </>
    )
}
