export class CatalogEngine {
    static parseCost(x: number) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    static getByType(type: string, array: Array<any>) {
        return array.filter(el => {
            if (el.productsSchema.type[0] === type) {
                return true
            } else {
                return false
            }
        })
    }
}
