import {Box} from "@mui/material";
//@ts-ignore
import {ReactComponent as VK} from "../../assets/icons/vk.svg";
//@ts-ignore
import {ReactComponent as OK} from "../../assets/icons/ok.svg";
//@ts-ignore
import {ReactComponent as YT} from "../../assets/icons/yt.svg";
//@ts-ignore
import {ReactComponent as RT} from "../../assets/icons/rt.svg";

export const icons = [
    {
        id: 0,
        icon: <VK/>,
        link: ''
    },
    {
        id: 1,
        icon: <OK/>,
        link: 'https://ok.ru/stanki.osnastka89106726456'
    },
    {
        id: 2,
        icon: <YT/>,
        link: 'https://www.youtube.com/channel/UCkpM358pZ0PcPz59YkixMtA'
    },
    {
        id: 3,
        icon: <RT/>,
        link: 'https://rutube.ru/channel/26629971/'
    },
]
