import React from "react";
import {Box} from "@mui/material";
import {CatalogCard} from "./CatalogCard";
import {IS_MOBILE} from "../../../config";
const mobileStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    alignItems: 'center'
}

const webStyle = {

    display: 'grid', gridTemplateColumns: 'repeat(4,1fr)', gridRowGap: 10, gridColumnGap: 10
}

export const CatalogTypeContent: React.FC<any> = ({content}) => {
    const style = IS_MOBILE ? mobileStyle : webStyle
    return (
        <Box sx={{width: '100%', marginTop: 5, ...style }}>
            {
                content.map((el: any) => <CatalogCard key={`${el.id}_catalog_card`} card={el} />)
            }

        </Box>
    )
}
