import {Box, Typography} from "@mui/material";
import {FC} from "react";
import {ProductBody} from "./Product.body";

export const ProductBox: FC<{product: any}> = ({product}) => {

    return (
        <Box sx={{ width: '100%', height: '100%'}}>
            <Box sx={{width: '100%', pb: 2, borderBottom: '1px solid #fff'}}>
                <Typography sx={{color: '#fff', fontSize: '1.5rem', fontWeight: 300}}>
                    {product.title}
                </Typography>
            </Box>
            <ProductBody product={product}/>
        </Box>
    )
}
