import {Box} from "@mui/material";
import React from "react";
import {useNavigate} from "react-router-dom";
import {catalogTypes} from "./CatalogTypes.mock";
import {CatalogTypeBox} from "./CatalogTypeBox";
import {CatalogEngine} from "./CatalogEngine.class";



export const CatalogContent: React.FC<any> = ({content}) => {
    const navigate = useNavigate()


    return (
        <Box sx={{display: 'flex', alignItems: 'center', flexDirection: 'column', width: '100%', gap: 10}}>
            {
                catalogTypes.map(el =>
                    <CatalogTypeBox
                        id={el.type}
                        key={`${el.id}_${el.type}_catalog`}
                        content={CatalogEngine.getByType(el.type, content)}
                        type={el.title} />)
            }

        </Box>
    )
}
