import React from "react";
import {IProps} from "../interfaces/props.interface";
import {Box} from "@mui/material";
import {Header} from "../components/Header/Header";
import {Footer} from "../components/Footer/Footer";
import {setter, useGlobalValue} from "elum-state/react";
import {SIZED_IMAGE} from "../states";
import CloseIcon from '@mui/icons-material/Close';

export const PageWrapper: React.FC<IProps> = ({children}) => {
    const sizedImage = useGlobalValue(SIZED_IMAGE)
    const clickOnOverlay = (event: any, close = false) => {
        if (event.target.tagName !== 'IMG' || close) {
            setter(SIZED_IMAGE, '')
        }

    }
    return (
        <>
            <Box position='relative' width='100vw' minHeight='100vh' sx={{backgroundImage: 'url(/assets/backgrounds/background_main.jpg)'}}>
                <Box width='100%' height='100%' sx={{backgroundColor: 'rgba(40,40,40,0.29)', backdropFilter: 'blur(5px)', position: 'absolute', zIndex: 0}}></Box>
                <Header/>
                {children}
                {
                    sizedImage !== '' ?
                        <Box onClick={clickOnOverlay}  sx={{cursor:  'pointer',position: 'absolute', top: 0, left: 0, width: '100vw', zIndex: 2, height: '100%', backgroundColor: 'rgba(0,0,0,0.75)', backdropFilter: 'blur(3px)'}}>

                            <Box sx={{position: 'absolute',top: '50%', left: '50%', transform: 'translate(-50%,-50%)', overflow: 'visible'}}>
                                <CloseIcon onClick={(e) => clickOnOverlay(e,true)} sx={{position: 'absolute',right: -40}}/>
                                <img style={{maxWidth: window.innerWidth - 100, maxHeight: 900, borderRadius: '10px', cursor: 'auto'}} src={sizedImage}/>
                            </Box>
                        </Box>
                        : <></>
                }
            </Box>
            <Footer/>
        </>
    )
}
