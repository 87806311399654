import {Box, Button, CircularProgress, TextField} from "@mui/material";
import React from "react";
import {setter} from "elum-state/react";
import {PAGE} from "../states";
import {client} from "../api/graphql/client/client";
import {GraphQL} from "../api/graphql/queres/GraphQL.queres";
import {AboutContent} from "../components/pages/About/Content";
import {ServicesContent} from "../components/pages/Services/ServicesContent";
import {WorksContent} from "../components/pages/Works/WorksContent";
import {useEnqueueSnackbar} from "../hooks/useSnackbar";
import {api} from "../api/axios/https";
import {IS_MOBILE} from "../config";

const style = {
    position: 'relative' as 'relative'
}


export const Delivery = () => {
    const nullForm = {
        name: '',
        phone: '',
        email: '',
        order: ''
    }
    const [form, setForm] = React.useState(nullForm)
    const {openSnackbar} = useEnqueueSnackbar()

    const sendForm = async (e: React.FormEvent) => {
        e.preventDefault()
        const isFormValidate =
            form.name !== '' &&
            form.phone !== '' &&
            form.email !== '' &&
            form.order !== ''
        if (!isFormValidate) {
            return openSnackbar({message: 'Заполните все поля!', variant: 'error'})
        }
        if (!/.*@.*\..*/.test(form.email)) {
            console.log(form.email)
            return openSnackbar({message: 'Укажите верную почту!', variant: 'error'})
        }
        try {
            //const {data} = await api.post('newOrder.php', form})

            const {data} = await fetch(`${process.env.REACT_APP_REST_URL as string}newOrder.php`, {
                method: "POST",
                body: JSON.stringify(form)
            }).then(async r => {
                const data = await r.json()
                return {data}
            })
            openSnackbar({message: data.detail, variant: `${data.status ? 'success' : 'error'}`})
            setForm(nullForm)

        } catch (e) {

        }
    }

    React.useEffect(() => {
        setter(PAGE, {
            title: 'Заказ',
            link: '/delivery'
        });
    },[])
    return (
        <Box style={style}>
            <Box className='center' sx={{width: '100%', height: `'fit-content'`, display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 10}}>
                <form style={IS_MOBILE ? {width: '100%'}: {}}  onSubmit={sendForm}>
                    <Box sx={{
                        padding: '40px 20px',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        //justifyContent: 'space-between',
                        flexDirection: 'column',
                        width: !IS_MOBILE ? 400 : '100%',
                        minHeight: 600,
                        backgroundColor: 'rgba(79,79,79,0.65)',
                        backdropFilter: 'blur(5px)',
                        borderRadius: '10px'}}>
                            <TextField
                                fullWidth
                                value={form.name}
                                onChange={({target: {value}}) => setForm({...form,name: value})}
                                label='Имя'
                                sx={{
                                overflow: 'visible',
                                "& *, &": {
                                    color: '#fff !important',
                                    fontWeight: 300,
                                },
                                "&  .MuiInputBase-root::before": {
                                    borderColor: '#fff !important'
                                },
                            }} variant="standard"/>
                        <TextField
                            fullWidth
                            value={form.email}
                            onChange={({target: {value}}) => setForm({...form,email: value})}
                            label='Почта'
                            sx={{
                                overflow: 'visible',
                                "& *, &": {
                                    color: '#fff !important',
                                    fontWeight: 300,
                                },
                                "&  .MuiInputBase-root::before": {
                                    borderColor: '#fff !important'
                                },
                            }} variant="standard"/>
                        <TextField
                            fullWidth
                            value={form.phone}
                            onChange={({target: {value}}) => setForm({...form,phone: value})}
                            label='Телефон'
                            sx={{
                                overflow: 'visible',
                                "& *, &": {
                                    color: '#fff !important',
                                    fontWeight: 300,
                                },
                                "&  .MuiInputBase-root::before": {
                                    borderColor: '#fff !important'
                                },
                            }} variant="standard"/>
                            <TextField
                                label='Заказ'
                                multiline
                                minRows={8}
                                maxRows={10}
                                value={form.order}
                                onChange={({target: {value}}) => setForm({...form,order: value})}
                                sx={{
                                    overflow: 'visible',
                                    width: '100%',
                                    "& *, &": {
                                        color: '#fff !important',
                                        borderColor: '#fff !important',
                                        borderRadius: '10px !important'
                                    },
                                    "& > .MuiInputBase-input:hover, &:hover": {
                                        borderColor: '#fff !important'
                                    }
                                }}
                            />
                        <Button  onClick={sendForm} fullWidth variant='outlined'> Отправить</Button>

                    </Box>
                </form>
            </Box>
        </Box>
    )
}
