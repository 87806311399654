import {ICard} from "./card.interface";
import React from "react";
import {Box, Typography} from "@mui/material";
import {IS_MOBILE} from "../../../../../config";
import {useNavigate} from "react-router-dom";
import {ITaxonomie} from "../../../../../interfaces/objects.interface";
import {IPropsTaxonomies} from "../../../../../interfaces/props.interface";

export const Card: React.FC<IPropsTaxonomies> = ({name, id,childrens,getTaxonomyByParentId, getContent}) => {
    console.log(id,name, childrens)
    const navigate = useNavigate()

    const handleClick = async () => {
        try {
            if (childrens) {
                await getTaxonomyByParentId(id, name)
            } else {
                await getContent(id, name)
            }
        } catch (e) {

        }
    }
    return (
        <Box
            onClick={handleClick}
            sx={{
            background: 'rgba(255, 255, 255, 0.5)',
            gridColumn: 'span 1',
            height: IS_MOBILE ? 150 : 200,
            cursor: 'pointer',
            transition: 'transform .2s',
            borderRadius: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            "&:hover": {
                transform: 'translateY(-10px)',
            }
        }}>
            <Typography sx={{fontSize: '1.2rem', maxWidth: 300, textAlign:'center', overflow: 'visible'}}>
                {name}
            </Typography>
        </Box>
    )

}
