import {FC} from "react";
import {Box, Typography} from "@mui/material";
import {CatalogEngine} from "../Catalog/CatalogEngine.class";
import {IS_MOBILE} from "../../../config";

export  const ProductDescription: FC<{product: any}> = ({product}) => {

    console.log(product)
    return (
        <Box sx={{width: IS_MOBILE ? `100%` : '50%', display: 'flex', flexDirection: 'column', gap: 1}}>
            <Box sx={{display: 'flex', gap: 1}}>
                <Typography sx={{color: 'rgba(187,187,187,0.96)'}}>Модель:</Typography>
                <Typography>{product.model}</Typography>
            </Box>

            <Box sx={{display: 'flex', gap: 1}}>
                <Typography sx={{color: 'rgba(187,187,187,0.96)'}}>Количество:</Typography>
                <Typography>{product.quantity}</Typography>
            </Box>

            <Box sx={{display: 'flex', gap: 1}}>
                <Typography sx={{color: 'rgba(187,187,187,0.96)'}}>Год:</Typography>
                <Typography>{product.year}</Typography>
            </Box>


            <Box sx={{display: 'flex', gap: 1}}>
                <Typography sx={{color: 'rgba(187,187,187,0.96)'}}>Цена:</Typography>
                <Typography>{CatalogEngine.parseCost(product.price)} ₽</Typography>
            </Box>

            <Box sx={{display: 'flex', gap: 1, flexDirection: 'column'}}>
                <Typography sx={{color: 'rgba(187,187,187,0.96)'}}>Описание:</Typography>
                <Typography>{product.description}</Typography>
            </Box>

        </Box>
    )
}
