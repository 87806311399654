import {Box, CircularProgress} from "@mui/material";
import React from "react";
import {setter} from "elum-state/react";
import {PAGE} from "../states";
import {client} from "../api/graphql/client/client";
import {GraphQL} from "../api/graphql/queres/GraphQL.queres";
import {AboutContent} from "../components/pages/About/Content";
import {IS_MOBILE} from "../config";
import {CatalogContent} from "../components/pages/Catalog/CatalogContent";

const style = {
    position: 'relative' as 'relative'
}


export const Catalog = () => {
    const [isLoading, setIsLoading] = React.useState(true)
    const [content, setContent] = React.useState('')

    const getContent = async () => {
        try {
            const {data} = await client.query({query: GraphQL.Products})
            setIsLoading(false)
            setContent(data.products.nodes)
        } catch (e) {

        }
    }

    React.useEffect(() => {
        getContent()
    },[])

    React.useEffect(() => {
        setter(PAGE, {
            title: 'Каталог',
            link: '/catalog'
        });
    },[])
    return (
        <Box style={style}>
            <Box className='center' sx={{marginTop: '25vh', width: '100%', height:  'fit-content', display: 'flex', justifyContent: 'center', mt: 10}}>
                {isLoading ? <CircularProgress/> :
                    <CatalogContent content={content}/>
                }
            </Box>
        </Box>
    )
}
