import React, {FC} from "react";
import {Box} from "@mui/material";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import {IS_MOBILE} from "../../../config";

const mobileBarWrapper = {
    width: '100%',
    overflowY: 'hidden',
    overflowX: 'auto',

}

const webBarWrapper = {
    height: '500px',
    //width: '100px',
    overflowY: 'auto',
    overflowX: 'hidden',
}

const mobileBar = {
    display: 'flex',
    gap: 1,
    width: 'fit-content',
    minWidth: '100%',
    justifyContent: 'center',
    alignItems: "center",
}

const webBar = {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    height: 'fit-content',
    //minHeight: '100%',
    justifyContent: 'center',
    alignItems: "center",
}
export const ProductMedia: FC<{media: any, video: string | null}> = ({media, video}) => {
    const [selectedIndex, setSelectedIndex] = React.useState(0)
    const mediaBar = media

    const setIndex = (index: number) => {
        if (index === selectedIndex) return 0
        setSelectedIndex(index)

    }
    return (
        <Box sx={{width: IS_MOBILE ? `100%` : '50%', display: 'flex', gap: 1, justifyContent: 'space-between', alignItems: IS_MOBILE ? 'center' : 'flex-start', flexDirection: IS_MOBILE ? 'column' : 'row'}}>


            <Box sx={IS_MOBILE ? mobileBarWrapper : webBarWrapper}>

                <Box sx={IS_MOBILE ? mobileBar : webBar}>

                {
                    mediaBar.map((el: any, i: number) => {
                        if (!el.video) {
                            return (
                            <Box key={`photo_${i}`} onClick={() => setIndex(i)} sx={{overflow: 'hidden', width: 100, height: 100, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '15px', position: 'relative', cursor: 'pointer'}}>
                                {
                                    selectedIndex === i && <Box sx={{width: '100%', height: '100%', backgroundColor: 'rgba(255,255,255,0.63)', position: 'absolute', backdropFilter: 'blur(3px)'}}/>
                                }
                                <img src={el} style={{width: '100%'}}/>
                            </Box>
                            )
                        } else {
                            return (
                                <Box key={`video_${i}`} onClick={() => setIndex(i)} sx={{overflow: 'hidden',width: 100, height: 100, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '15px', position: 'relative', cursor: 'pointer'}}>
                                    {
                                        selectedIndex === i ?
                                            <Box sx={{width: '100%', height: '100%', backgroundColor: 'rgba(255,255,255,0.63)', position: 'absolute', backdropFilter: 'blur(3px)'}}/> :
                                            <Box sx={{width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.63)', position: 'absolute', backdropFilter: 'blur(3px)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                <PlayCircleIcon sx={{ color: '#fff', width: 40, height: 40}}/>
                                            </Box>
                                    }
                                    {video !== null && <Box dangerouslySetInnerHTML={{__html: video}} sx={{"& iframe":{width: "100% !important"}}}/>}
                                </Box>
                            )
                        }
                    })
                }
                </Box>
            </Box>

            <Box sx={{width: '80%', display: 'flex', justifyContent: 'center'}}>
                {
                    mediaBar[selectedIndex].video ?
                        (video !== null && <Box dangerouslySetInnerHTML={{__html: video}} sx={{"& iframe":{width: `${IS_MOBILE ? '100%' : '500px'} !important`,borderRadius: '15px', border: '1px solid #fff' }}}/>) :
                        <Box sx={{maxHeight: 600, maxWidth: 400, display:'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <img src={mediaBar[selectedIndex]} style={{maxWidth: '100%', maxHeight: '100%',  borderRadius: '15px', border: '1px solid #fff'}}/>
                        </Box>

                }

            </Box>

        </Box>
    )
}
